<template>
    <div class="printPrice">
        <div class="searchLine">
            <el-input v-model="keyword" placeholder="输入关键字查询"></el-input>
            <el-button type="primary" @click="clickSearch">查 询</el-button>
        </div>
        <div class="tableContainer">
            <el-table
            :data="tableData"
            style="width: 100%"
            height="100%">
                <el-table-column
                    prop="name"
                    label="商品名称">
                </el-table-column>
                <el-table-column
                    prop="sn"
                    label="货号"
                    width="240">
                </el-table-column>
                <el-table-column
                    prop="ourShopPrice"
                    label="价格（￥）"
                    width="180">
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                        <el-button type="success" plain size="mini" @click="clickPrint(scope.row)">打印</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="pagi">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage4"
                :page-sizes="[100, 200, 300, 400]"
                :page-size="pageSize"
                layout="prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>

        <priceTicketComp :goodsObj="goodsDetail" :dialogVisible="dialogVisible" @close="dialogVisible=false"></priceTicketComp>
    </div>
</template>

<script>
import {getGoodsSearch,getGoodsDetail} from "@/axios/api"
import priceTicketComp from "@/components/priceTicketComp"
export default {
    components:{
        priceTicketComp
    },
    data(){
        return{
            keyword:"",
            currentPage4:1,
            pageSize:20,
            tableData:[],
            total:0,
            dialogVisible:false,
            goodsDetail:{},
        }
    },
    methods: {
        handleSizeChange(val) {
            // // console.log(`每页 ${val} 条`);
            this.pageSize=val;
            this.gainGoodsList()
        },
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.currentPage4=val;

            this.gainGoodsList()
        },
        gainGoodsList(){
            getGoodsSearch({
                keyword:this.keyword,
                pageNum:this.currentPage4,
                pageSize:this.pageSize,
            }).then(res=>{
                // console.log("商品列表",res);
                if(res.code==200){
                    this.total=Number(res.data.total);
                    this.tableData=res.data.rows;
                }
            })
        },

        clickPrint(item){
            getGoodsDetail({goodsId:item.goodsId}).then(res=>{
                // console.log("商品详情",res);
                if(res.code==200){
                    this.goodsDetail=res.data;
                    this.dialogVisible=true;
                }
            })
        },
        clickSearch(){
            this.currentPage4=1;
            this.gainGoodsList();
        }
    },
    created() {
        this.gainGoodsList();
    },
}
</script>

<style lang="less" scoped>
.printPrice{
    height: 100%;
    .searchLine{
        height: 40px;
        display: flex;
        .el-input{
            width: 240px;
            margin-right: 15px;
        }
    }
    .tableContainer{
        height: calc(100% - 130px);
        margin-top: 20px;
        /deep/.el-table th.el-table__cell{
            background: #F8F8F9;
        }
    }
    .pagi{
        margin-top: 20px;
        text-align: center;
        /deep/.btn-next , /deep/.btn-prev{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
        }
        /deep/ .number , /deep/.el-icon-more, /deep/.btn-quickprev, /deep/.btn-quicknext{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
            &.active{
                background-color: rgba(35, 125, 92, 1)!important;
            }
        }
        /deep/.el-pagination__jump{
            height: 46px;
            line-height: 46px;
            font-size: 16px;
            font-weight: 500;
            columns: rgba(0, 0, 0, 1);
            .el-pagination__editor{
                height: 46px;
                .el-input__inner{
                    height: 46px;
                }
            } 
        }
    }
}
</style>